<template>
  <div
    :style="{ backgroundImage: `url(${$asset('/images/sections/auth/login_background.jpg')})` }"
    class="AuthLayout is-cover-image is-flex is-column"
  >
    <div class="is-flex is-column is-aligned-middle is-aligned-center is-expanded">
      <div class="columns is-width-full is-aligned-center is-position-relative">
        <!--<div class="AuthLayout__FloatingText p-r-m p-l-m has-text-left is-hidden-mobile is-hidden-tablet-only is-width-1/3">
          <heading color="white" size="2"><span v-html="$t('pages.auth.side_title')"/></heading>
          <div class="content is-size-5">
            <ul class="has-text-serif has-text-weight-semibold">
              <li v-for="i in 6" :key="i">{{ $t(`pages.auth.side_bullets.${i}`) }}</li>
            </ul>
          </div>
        </div>-->
        <div class="column is-12-mobile is-3-desktop is-6-tablet">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "~utils";

.AuthLayout {
  background-position: center center;
  min-height: 100vh;

  &__FloatingText {
    position: absolute;
    top: 0;
    left: 50px;
    transform: translateY(5%);
    color: $hf__color-white;
  }

  .LanguageSwitcher {
    .el-input__inner {
      background: none;
      border: none;
      color: $hf__color-white;
    }
  }

  .links {
    display: flex;
    text-align: right;
    flex-direction: column;
  }

  footer {
    margin-top: $margin-small;
    text-align: center;
    font-size: $hf__font-label-small;
  }
}
</style>

<template>
  <auth>
    <div class="ForgottenPassword card">
      <div class="card-content">
        <heading size="4" class="has-text-centered">
          {{ $t('components.forgot_password.title') }}
        </heading>
        <transition>
          <div v-if="isRequested" class="successful_message">
            <h3>{{ $t('components.forgot_password.email_on_way') }}</h3>

            <p>{{ $t('components.forgot_password.follow_the_directions') }}</p>

            <img
              :src="$asset('/images/forms/success_check@2x.png')"
              class="success_graphic"
              alt="Welcome to Hypefactors. Its gonna be hypetastic"
            >
          </div>
          <div v-else>
            <transition name="fade">
              <div v-if="errorMsg" class="notification is-danger error-msg m-b-m">
                {{ errorMsg }}
              </div>
            </transition>

            <heading size="5">
              {{ $t('components.forgot_password.no_worries') }}
            </heading>

            <p class="m-b-m">
              {{ $t('components.forgot_password.enter_the_email') }}
            </p>

            <form @submit.prevent="resetPassword">
              <div class="is-multiline">
                <form-field
                  :validator="$v.email"
                  :label="$t('forms.email')"
                >
                  <input
                    v-model="email"
                    type="email"
                    class="input"
                    tabindex="5"
                    name="email"
                    @input="$v.email.$touch()"
                  >
                </form-field>
              </div>
              <br>
              <div class="field">
                <p class="control">
                  <v-button
                    :loading="isLoading"
                    :disabled="isRequested"
                    type="submit"
                    class="is-primary is-fullwidth"
                  >
                    {{ $t('components.forgot_password.reset_password') }}
                  </v-button>
                </p>
                <br>
              </div>
            </form>
          </div>
        </transition>
      </div>
    </div>
  </auth>
</template>

<script>
import Auth from './Auth'
import { email, required } from 'vuelidate/lib/validators'

/**
 * @module ForgottenPassword
 */
export default {
  name: 'ForgottenPassword',
  components: { Auth },
  validations: {
    email: { email, required }
  },
  data () {
    return {
      email: '',
      errorMsg: '',
      isRequested: false,
      isLoading: false
    }
  },
  methods: {
    resetErrors () {
      this.errorMsg = ''
    },

    resetPassword () {
      this.$v.$touch()

      if (this.$v.$error) {
        return false
      }

      this.resetErrors()

      // Using the mobile API to do a reset password
      this.isLoading = true

      this.$api
        .post('/passwords/forgot', { email: this.email })
        .then((response) => {
          this.isLoading = false
          this.isRequested = true
        })
        .catch((error) => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.password_reset_email_could_not_be_sent'))
        })
    }
  }
}
</script>
